/**
 * 补单相关api
 */
import request from "@/utils/request";

/**
 * 获取补单列表
 * @param params
 */
export function getSupplementList(params) {
  return request({
    url: '/seller/trade',
    method: 'get',
    params
  })
}

/**
 * 取消补单
 * @param params
 */
export function cancelSupplement(params) {
  return request({
    url: '/seller/trade/cancelTradeTh',
    method: 'post',
    data: params
  })
}

/**
 * 企业创建补单订单
 * @param data
 */
export function createSupplementOrder(data) {
  return request({
    url: '/seller/trade/exchange/createTradeTh',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * 查看补单相关联记录
 * @param params
 */
export function getSupplementRecord(params) {
  return request({
    url: '/seller/trade/repairOrderList',
    method: 'get',
    params
  })
}

/**
 * 补单-查询用户的收货地址
 * @param params
 */
export function getMemberAddressList(params) {
  return request({
    url: '/seller/members/addresses/addresses/cardList',
    method: 'get',
    params
  })
}

/**
 * 补单-添加C端用户地址
 */
export function addMemberAddress(params, cardKey) {
  return request({
    url: `seller/members/addresses/card/addressTh/${cardKey}`,
    method: 'post',
    data: params,
  })
}

/**
 * 补单-企业端取消订单
 */
export function cancelTrade(params) {
  return request({
    url: '/seller/trade/cancelTradeTh',
    method:' post',
    data: params
  })
}
